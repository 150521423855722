import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'
import useMeasure from 'react-use-measure'
import styled from 'styled-components'
import 'mapbox-gl/dist/mapbox-gl.css'
import ReactMapGL, { Popup, Marker } from 'react-map-gl'
import { graphql } from 'gatsby'
import InfiniteScroll from 'react-infinite-scroller'
import { debounce } from 'debounce'

import Page, { Section } from '../../shared/page-commons'
import { referenceLookup } from '../../shared/reference'
import { FilterGroup, FilterButton, useRadioToggles } from '../../shared/filter'
import { screen } from '../../shared/breakpoints'
import SectionHeader from '../../shared/section-header'
import * as defaults from '../../shared/markdown-defaults'
import AriadnaBanner from './_ariadna-banner'
import Tag from '../../shared/tag'
import { useSearch } from '../../shared/search'

const MAPBOX_TOKEN =
  'pk.eyJ1IjoiZXNhYWN0IiwiYSI6ImNqdDc3cXFvODBlc2UzeWxocmJ0dG1jODcifQ.6lVbcGRBY6iyVgj2GruxJg'

const ARIADNA_FIELDS_TO_QUERY = [
  // year is retrieved from the kickOff date
  'year',
  'reference',
  'researchFellow',
  'title',
  'year',
  'university',
  'universityDepartment',
  'universityCountry',
  'status',
]

const ITEMS_PER_BATCH = 15

const FILTER_LOOKUP = {
  ...referenceLookup,
  // underscores serve to make it stand out.
  ___others___: 'Others',
}

const FILTER_KEYS = Object.keys(FILTER_LOOKUP)

function arrayOfLength(length) {
  return new Array(length).fill(0)
}

function parseCoordinate(coordinate) {
  if (typeof coordinate !== 'string') return null

  let coordinateStrings = coordinate.split(',')

  if (coordinateStrings.length < 2) {
    return null
  }

  coordinateStrings = coordinateStrings.map((string) => parseFloat(string))

  return {
    latitude: coordinateStrings[0],
    longitude: coordinateStrings[1],
  }
}

const StyledPin = styled.svg`
  fill: var(--accent-bright);
  width: 28px;
  height: 28px;
  cursor: pointer;
  transform: translate(-14px, -28px);
`

const University = styled.h3`
  margin-top: 4px;
  text-align: center;
  font-family: var(--font-body);
  font-size: 0.9rem;
`

const Link = styled(defaults.a)`
  font-family: var(--font-body);
  display: block;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 5px;
`

const StyledMap = styled.div`
  .mapboxgl-popup {
    max-width: 200px;
  }

  .mapboxgl-popup-close-button {
    padding: 5px;
  }
`

function Pin(props) {
  return (
    <StyledPin
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </StyledPin>
  )
}

function AriadnaInfo(props) {
  const { ariadna } = props

  return (
    <>
      <University>{ariadna.title}</University>
      <Link href={ariadna.reportUrl}>Read report</Link>
    </>
  )
}

const StyledAriadnaStudy = styled.div`
  padding-bottom: 0.7rem;
  margin: 20px 0;
  line-height: 150%;
`

const Author = styled.div`
  font-style: normal;
  display: block;
  font-size: 1.05rem;
`

const Title = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  display: inline;
`

const AriadnaStudy = React.memo((props) => {
  const {
    studyDescriptionUrl,
    status,
    reference,
    title,
    researchFellow,
    reportUrl,
    universityUrl,
    university,
    universityDepartmentUrl,
    universityDepartment,
  } = props.ariadna

  const isOngoing = status.toLowerCase() === 'ongoing'

  return (
    <StyledAriadnaStudy role="listitem">
      {isOngoing && (
        <>
          <Tag css="line-height: 1.5;">Ongoing</Tag>
          <br />
        </>
      )}
      <Title>
        {title} ({reference})
      </Title>
      <div css="padding-left: 2rem;">
        <Author>{researchFellow}</Author>
        <defaults.a href={studyDescriptionUrl}>
          Initial study description
        </defaults.a>
        {reportUrl && (
          <>
            {' · '}
            <defaults.a href={reportUrl}>Report</defaults.a>
          </>
        )}
        <br />
        <defaults.a href={universityUrl}>{university}</defaults.a>
        <br />
        <defaults.a href={universityDepartmentUrl}>
          {universityDepartment}
        </defaults.a>
      </div>
    </StyledAriadnaStudy>
  )
})

const Markers = React.memo((props) => {
  const { ariadnaByKey, onClick, queryResultKeys } = props

  return queryResultKeys.map((key) => {
    const coordinate = parseCoordinate(ariadnaByKey[key].universityCoordinate)

    if (!coordinate) return null

    return (
      <Marker
        key={key}
        longitude={coordinate.longitude}
        latitude={coordinate.latitude}
      >
        <Pin onClick={() => onClick(key)} />
      </Marker>
    )
  })
})

function Map(props) {
  const { ariadnaByKey, queryResultKeys } = props

  const [ref, { width }] = useMeasure({ polyfill: ResizeObserver })
  const [ariadnaKeyActive, setAriadnaKeyActive] = useState()
  const [viewport, setViewport] = useState({
    height: 500,
    longitude: 11.582,
    latitude: 48.1351,
    zoom: 3,
  })

  const popupCoordinate = ariadnaKeyActive
    ? parseCoordinate(ariadnaByKey[ariadnaKeyActive].universityCoordinate)
    : null

  return (
    <StyledMap ref={ref}>
      <ReactMapGL
        {...viewport}
        width={width}
        mapStyle="mapbox://styles/mapbox/dark-v10"
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onViewportChange={setViewport}
      >
        <Markers
          ariadnaByKey={ariadnaByKey}
          queryResultKeys={queryResultKeys}
          onClick={setAriadnaKeyActive}
        />
        {popupCoordinate && (
          <Popup
            tipSize={5}
            anchor="bottom"
            offsetTop={-15}
            longitude={popupCoordinate.longitude}
            latitude={popupCoordinate.latitude}
            closeOnClick={false}
            onClose={() => setAriadnaKeyActive(null)}
          >
            <AriadnaInfo ariadna={ariadnaByKey[ariadnaKeyActive]} />
          </Popup>
        )}
      </ReactMapGL>
    </StyledMap>
  )
}

const SearchInput = styled.input`
  -webkit-appearance: none;
  border: none;
  display: block;
  padding: 15px 20px;
  background: var(--background);
  border-radius: 2px;
  width: 100%;
  font-size: 1rem;
  color: var(--heading);
  outline-color: var(--accent-bright);
  min-width: 250px;
  margin-top: 20px;
  margin-bottom: 20px;

  ::placeholder {
    color: var(--subtle);
  }
`

const SectionHeading = styled.h2`
  font-weight: bold;
  font-size: 1.7rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-family: var(--font-heading);
  line-height: 1.35;
  color: var(--heading);

  @media ${screen.sm} {
    font-size: 1.8rem;
  }

  @media ${screen.md} {
    font-size: 2.125rem;
  }
`

export default function AriadnaSearchPage(props) {
  // The raw array of ariadnas as received from graphql
  const ariadnas = props.data.allAriadnaDatabaseXlsxRawData.nodes

  // In order to improve render performance only a part of the ariadnas
  // are initially rendered. When the user comes near the end of the currently
  // rendered items scrollBatch increases so to render the next batch of ariadnas.
  // This technique is called infinite scrolling.
  const [scrollBatch, setScrollBatch] = useState(0)

  // Change data structure of ariadnas from array to object.
  // This improves lookup speed for further use.
  const ariadnaByKey = useMemo(() => {
    let result = {}

    for (const ariadna of ariadnas) {
      const dateComponents = ariadna.kickOffDate
        ?.split('/')
        .map((num) => parseInt(num))

      const containsInvalidNumber = dateComponents
        ?.map(isFinite)
        .includes(false)

      if (
        !dateComponents ||
        containsInvalidNumber ||
        dateComponents.length !== 3
      ) {
        console.warn(
          `Kick off date of ariadna '${ariadna.title}' is not specified correctly. Date must be specified in dd/mm/yyyy format. This study is not included on this page until the date is fixed.`
        )
        continue
      }

      result[ariadna.reference] = ariadna

      let [, , year] = dateComponents
      // This is a hacky fix. A real fix would be to add dates properly to the excel file.
      if (year < 1900) year += 2000
      result[ariadna.reference].year = year.toString()
    }

    return result
  }, [ariadnas])

  // The result of all the filtering and querying steps.
  const [queryResultKeys, setQueryResultKeys] = useState(() => {
    // Initialize with all the keys
    return Object.keys(ariadnaByKey)
  })

  const { query, search, indexDocument, onQueryChange } = useSearch(
    function onChange(query) {
      debouncePerformQuery({ query })
    }
  )

  const [activeFilterKey, toggleFilterByKey] = useRadioToggles(
    FILTER_KEYS,
    function onToggle(activeFilterKey) {
      debouncePerformQuery({ activeFilter: activeFilterKey })
    }
  )

  // Filles the searchApi with items to be queried
  useEffect(() => {
    for (const key in ariadnaByKey) {
      let queryString = ''

      // Concatenate all the searchable fields separated with a space.
      for (const ariadnaField of ARIADNA_FIELDS_TO_QUERY) {
        queryString += `${ariadnaByKey[key][ariadnaField]} `
      }

      indexDocument(key, queryString)
    }
  }, [ariadnaByKey, indexDocument])

  // Store as a ref to aggregate the query and filter but without needing to update
  // the dependencies of either the query or activeFilter that do not need to update
  // since only one of the two variables can possibly change on a new render.
  const performQueryRef = useRef()

  // Makes sure that the reference is always up-to-date with the latest query and activeFilter
  useEffect(() => {
    performQueryRef.current = function performQuery(options) {
      // Since this function is called before the new render where either
      // query or activeFilter is updated the invoker can provide the new value.
      // The other value will be the one that was set on the render when performQuery is invoked.
      const {
        // Use provided query, fallback to current query state
        // Specified in the following pattern: provided: asVariableName = fallback
        query: queryToPerform = query,
        // Use provided activeFilter, fallback to current activeFilter state
        // Specified in the following pattern: provided: asVariableName = fallback
        activeFilter: filterToUse = activeFilterKey,
      } = options

      search(queryToPerform).then((matchingKeys) => {
        let filteredMatchingKeys = matchingKeys

        if (filterToUse) {
          if (filterToUse === '___others___') {
            // show all the ariadnas that are not in a standardized filter
            filteredMatchingKeys = matchingKeys.filter(
              (key) => !FILTER_KEYS.includes(ariadnaByKey[key].actCategory)
            )
          } else {
            filteredMatchingKeys = matchingKeys.filter(
              (key) => ariadnaByKey[key].actCategory === filterToUse
            )
          }
        }

        setQueryResultKeys(filteredMatchingKeys)
        // Reset the scroll batch to improve render speed.
        // When user scrolls to the end more will be rendered.
        setScrollBatch(0)
      })
    }
  }, [activeFilterKey, query, ariadnaByKey, search])

  // Prevent performing an expensive query on each keystroke.
  // When the user didn't type for 100ms perform the query.
  const debouncePerformQuery = useMemo(() => {
    return debounce((options = {}) => {
      // Because the actual querying happens on a web worker
      // the result is provided asynchronous.
      return performQueryRef.current(options)
    }, 100)
  }, [])

  // Change data structure to reflect what should be rendered.
  const resultByYear = useMemo(() => {
    let result = {}

    for (const key of queryResultKeys) {
      const { year } = ariadnaByKey[key]

      if (!result[year]) {
        result[year] = []
      }

      result[year].push(key)
    }

    return result
  }, [ariadnaByKey, queryResultKeys])

  // Sort the years in the query result in descending order.
  const sortedYears = useMemo(() => {
    return Object.keys(resultByYear)
      .map((yearAsString) => parseInt(yearAsString))
      .sort((a, b) => b - a)
      .map((yearAsNumber) => yearAsNumber.toString())
  }, [resultByYear])

  // Map continuous numbers to items grouped and sorted by year.
  const getRenderDataByIndex = useCallback(
    (index) => {
      // Keep track of the total number of ariadnas in the previously checked years.
      let visitedResults = 0

      for (const year of sortedYears) {
        if (index < visitedResults + resultByYear[year].length) {
          const nthItemInYear = index - visitedResults
          const key = resultByYear[year][nthItemInYear]

          return {
            ariadna: ariadnaByKey[key],
            nthItemInYear,
            year,
          }
        }

        visitedResults += resultByYear[year].length
      }

      throw new Error(`Ariadna could not be found by index ${index}`)
    },
    [ariadnaByKey, resultByYear, sortedYears]
  )

  let itemsToRender = (scrollBatch + 1) * ITEMS_PER_BATCH
  const canLoadMore = itemsToRender < queryResultKeys.length

  // Prevent trying to render more than the number of results.
  if (!canLoadMore) {
    itemsToRender = queryResultKeys.length
  }

  return (
    <Page bgColor="surface">
      <AriadnaBanner />
      <Section textColumn>
        <SectionHeader>
          Search for Ariadna Studies ({queryResultKeys.length})
        </SectionHeader>
        <defaults.p>
          Discover our previous and ongoing collaborations between the Advanced
          Concepts Team and European Academia. All reports of completed studies
          are available in pdf format.
        </defaults.p>
      </Section>
      <Section bgColor="background">
        <Map ariadnaByKey={ariadnaByKey} queryResultKeys={queryResultKeys} />
      </Section>
      <Section bgColor="surface">
        <SearchInput
          type="search"
          placeholder="Search..."
          value={query}
          onChange={onQueryChange}
        />
        <FilterGroup>
          {Object.entries(FILTER_LOOKUP).map(([filterKey, filterName]) => (
            <FilterButton
              key={filterKey}
              onClick={toggleFilterByKey[filterKey]}
              active={activeFilterKey === filterKey}
            >
              {filterName}
            </FilterButton>
          ))}
        </FilterGroup>
        <InfiniteScroll
          loadMore={() => setScrollBatch(scrollBatch + 1)}
          hasMore={canLoadMore}
          loader={null}
        >
          {arrayOfLength(itemsToRender).map((_, index) => {
            const renderData = getRenderDataByIndex(index)
            const { ariadna, nthItemInYear, year } = renderData

            return (
              <React.Fragment key={ariadna.reference}>
                {/* Render the section heading before the first item of a year. */}
                {nthItemInYear === 0 && (
                  <SectionHeading>
                    {year} ({resultByYear[year].length})
                  </SectionHeading>
                )}
                <AriadnaStudy ariadna={ariadna} />
              </React.Fragment>
            )
          })}
        </InfiniteScroll>
      </Section>
    </Page>
  )
}

export const query = graphql`
  query AriadnaSearchPage {
    allAriadnaDatabaseXlsxRawData {
      nodes {
        title: R
        reference: Ariadna_Ref
        studyDescriptionUrl: Study_desc_url
        reportUrl: Report_url
        researchFellow: RF
        university: University
        universityUrl: Uni_url
        universityDepartment: Uni_department
        universityDepartmentUrl: Uni_dept_url
        universityCoordinate: Uni_latitude__longitude
        universityCountry: Uni_country
        kickOffDate: Kick_off_date
        status: Status
        actCategory: ACT_category
      }
    }
  }
`
